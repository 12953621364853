.sltBox {
    margin-bottom: 9px;
  }
  

  .marginToolbar {
    margin: -20px 5px 8px 5px;
  }
  

  .dx-datagrid .dx-header-filter-empty {
    color: #337ab7 !important;
}