.first-group,
.second-group {
  padding: 20px;
}

.second-group {
  background-color: rgba(191, 191, 191, 0.15);
}

.form-avatar {  
  border-radius: 50%;  
  display: flex;  
  justify-content: center;
  align-items: center;
  height: 125px;  
  width: 125px;    
}

.level1{
  border: 3px solid #1C8C97;
}

.level2{
  border: 3px solid rgb(30, 141, 233);
}

.level3{
  border: 3px solid rgb(17, 128, 7);
}

.level4{
  border: 3px solid rgb(233, 182, 30);
}

.level5{
  border: 3px solid rgb(199, 77, 56);
}

.backGroudLic{  
  border-radius: 50%;  
  display: flex;  
  justify-content: center;
  align-items: center;
  height: 100px;  
  width: 100px;    
}

.licOk{  
  background-image: url('./images/LicOk.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

}

.licErr{
  background: url('./images/licErr.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

}

.form-avatar p{  
  text-align: center;
  font-size: 60px;
  color: dimgray;
}