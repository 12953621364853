.left {
  float: left;
  width: 400px;
  height: 1000px;
  padding: 20px;
  background: rgba(191, 191, 191, 0.15);
  margin-right: 30px;
}

.right {
  overflow: hidden;
}

@media only screen and (max-width: 750px) {
  .left {
    float: left;
    width: 100%;
    height: 350px;
    padding: 20px;
    background: rgba(191, 191, 191, 0.15);
    margin-right: 30px;
    margin-bottom: 30px;
  }
  .right {
    padding-top: 50px;
    margin: 10px !important;
  }
}

.left .list .dx-list-group-header {
  color: #3476e7;
  font-weight: normal;
  font-style: inherit;
  font-size: 18px;
}

.left .list .hotel {
  float: left;
}

.left .list .hotel .name {
  font-weight: bold;
}

.type {
  margin: 3px 0 5px 0;
  height: 14px;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2218px%22%20height%3D%2214px%22%20%3E%3Cpolyline%20fill%3D%22%23F05B41%22%20points%3D%227.5%2C0%209.972%2C4.399%2014.999%2C5.348%2011.5%2C9.015%2012.135%2C14%207.5%2C11.866%202.865%2C14%203.5%2C9.015%200.001%2C5.348%205.028%2C4.399%207.5%2C0%20%22%2F%3E%3C%2Fsvg%3E');
  background-size: 18px 14px;
}

.type.gold {
  width: 54px;
}

.type.platinum {
  width: 72px;
}

.type.diamond {
  width: 90px;
}

.price-container {
  float: right;
  padding-top: 13px;
}

.price-container > div {
  display: inline-block;
}

.price-container .price {
  font-size: 25px;
}

.price-container .caption {
  font-size: 11px;
  line-height: 12px;
  padding-left: 2px;
  opacity: 0.7;
}

.buttonState {
  display: flex;
  align-items: center;
  float: right;
}

.chat-message {
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
  max-width: 60%;
  word-wrap: break-word;
  position: relative;
}

.chat-message-incoming {
  
  text-align: left;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 3px;
  padding: 5px;
  border-radius: 5px;  
  border-color: #a7a7a7;
}

.chat-message-outgoing {
  background-color: #17a2b8;
  color: white;
  text-align: right;
}

.chat-message-date {
  font-size: 12px;
  margin-right: 5px;
  color: green;
  display: inline-block;
  vertical-align: middle;
  margin-left: 3px;
}

.chat-message-attach {
  font-size: 12px;
  margin-right: 5px;
  color: rgb(128, 0, 0);
  display: inline-block;
  vertical-align: middle;
  margin-left: 3px;
}

.chat-message-author {
  font-size: 14px;
  margin-bottom: 5px;
  display: inline-block;
  display: inline-block;
  vertical-align: middle;
}
.chat-message-visible-all {
  font-size: 12px;
  margin-right: 5px;
  color: rgb(24, 84, 212);
  display: inline-block;
  vertical-align: middle;
}

.chat-message-text {
  font-size: 16px;
}

.dx-texteditor-input {
  border-radius: 0;
}

.dx-list-item-content {
  display: flex;
  flex-direction: column;
}

.ScrollViewChat {
  background-color: #ede9e9;
  margin-top: 5px;
  border-radius: 5px;
  padding: 5px;
}

.contain {
  position: relative;
  margin: 10px;
}

.conversation-card {
  margin-top: -26px;
  margin-bottom: -5px;
}

.conversation-header {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  background-color: #ccc; /* Reemplaza con el color dinámico */
}

.header-text {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.header-text > div {
  margin-right: 8px;
}

.conversation-message {
  display: flex;
}
